import { useState, RefObject, useEffect } from 'react';

interface IuseCarouselProps {
    ref: RefObject<HTMLDivElement>;
    countItems: number;
}

export enum DIRECTION {
    prev,
    next,
}

export enum CLASSES {
    carousel = '.carousel-container',
    container = '.container',
    card = '.card',
}

export const useCarousel = ({ ref, countItems }: IuseCarouselProps) => {
    const [prevBtnIsActive, setPrevBtnIsActive] = useState(false);
    const [nextBtnIsActive, setNextBtnIsActive] = useState(true);
    const [prevScrollPosition, setPrevScrollPosition] = useState(0);
    const [current, setCurrent] = useState<HTMLDivElement>();
    const [dotActive, setDotActive] = useState(0);
    const [withDot, setWithDot] = useState(false);
    const [containerWidth, setContainerWidth] = useState(0);

    useEffect(() => {
        if (ref.current) {
            const carouselWidth = ref.current?.querySelector(CLASSES.carousel)?.clientWidth || 0;
            const containerWidth = ref.current?.closest(CLASSES.container)?.clientWidth || 0;
            ref.current.scrollLeft = 0;
            setWithDot(carouselWidth > containerWidth);
            setCurrent(ref.current);
            setContainerWidth(containerWidth);
        }
        setPrevScrollPosition(0);
        setPrevBtnIsActive(false);
        setNextBtnIsActive(true);
    }, [ref, countItems]);

    const onClickHandler = (direction: DIRECTION) => {
        if (!current) return;
        const itemWidth = current?.querySelector(CLASSES.card)?.clientWidth || 0;
        const carouselWidth = current?.querySelector(CLASSES.carousel)?.clientWidth || 0;
        const containerWidth = current?.closest(CLASSES.container)?.clientWidth || 0;

        const gapWidth = (carouselWidth - itemWidth * countItems) / (countItems - 1);
        const itemWidthWithGap = itemWidth + gapWidth;

        const maxScrollPosition = carouselWidth - containerWidth;

        const countVisibleItems = Math.floor((containerWidth + gapWidth) / itemWidthWithGap);
        const visibleItemsWidth = countVisibleItems * itemWidthWithGap;
        const lastScrollWidth = maxScrollPosition % visibleItemsWidth;

        const newScrollPositionForPrevBtn =
            prevScrollPosition === maxScrollPosition && lastScrollWidth
                ? maxScrollPosition - lastScrollWidth
                : Math.max((current.scrollLeft || 0) - visibleItemsWidth, 0);

        const newScrollPositionForNextBtn = Math.min(
            (current.scrollLeft || 0) + visibleItemsWidth,
            maxScrollPosition
        );

        const currentScroll =
            direction === DIRECTION.prev
                ? newScrollPositionForPrevBtn
                : newScrollPositionForNextBtn;

        current.scrollLeft = currentScroll;
    };

    const scrollToFitItem = () => {
        if (!current) return;

        const itemWidth = current?.querySelector(CLASSES.card)?.clientWidth || 0;
        const carouselWidth = current?.querySelector(CLASSES.carousel)?.clientWidth || 0;
        const containerWidth = current?.closest(CLASSES.container)?.clientWidth || 0;

        const gapWidth = (carouselWidth - itemWidth * countItems) / (countItems - 1);
        const itemWidthWithGap = itemWidth + gapWidth;

        const maxScrollPosition = carouselWidth - containerWidth;

        const countVisibleItems = Math.floor((containerWidth + gapWidth) / itemWidthWithGap);
        const visibleItemsWidth = countVisibleItems * itemWidthWithGap;
        const currentScrollPosition = current.scrollLeft;
        const scrollStep = Math.floor(containerWidth / itemWidthWithGap);
        const widthCurrentScroll = currentScrollPosition - prevScrollPosition;

        let countScrolledItems = Math.round(currentScrollPosition / itemWidthWithGap);
        if (
            widthCurrentScroll > 0 &&
            widthCurrentScroll < visibleItemsWidth &&
            countScrolledItems % scrollStep
        ) {
            countScrolledItems += 1;
        }

        const fixedSrollPosition = countScrolledItems * itemWidthWithGap;
        const newScrollPosition = Math.min(fixedSrollPosition, maxScrollPosition);

        if (fixedSrollPosition !== currentScrollPosition) {
            current.scrollLeft = newScrollPosition;
        }

        setPrevScrollPosition(newScrollPosition);
        setDotActive(Math.round(currentScrollPosition / itemWidthWithGap));
        newScrollPosition ? setPrevBtnIsActive(true) : setPrevBtnIsActive(false);

        newScrollPosition < maxScrollPosition
            ? setNextBtnIsActive(true)
            : setNextBtnIsActive(false);
    };

    const debounce = (func: () => void, timeout = 400) => {
        let timer: ReturnType<typeof setTimeout>;
        return function (...args: Parameters<() => void>) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(args);
            }, timeout);
        };
    };

    const onScrollHandler = debounce(() => scrollToFitItem(), 300);

    return {
        prevBtnIsActive,
        setPrevBtnIsActive,
        nextBtnIsActive,
        setNextBtnIsActive,
        onClickHandler,
        onScrollHandler,
        DIRECTION,
        withDot,
        dotActive,
        containerWidth,
    };
};
